<template>
  <div :class="alertClass" role="alert">
    <i :class="['fa', icon, 'mr-2']" aria-hidden="true"></i>
    {{ message }}
  </div>
</template>

<!-- ErrorMessage.vue -->
<script setup>
import { computed } from "vue";

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "danger",
    validator: (value) => ["danger", "warning", "info"].includes(value),
  },
});

const icon = computed(() => {
  switch (props.type) {
    case "warning":
      return "fa-exclamation-triangle";
    case "info":
      return "fa-info-circle";
    default:
      return "fa-times-circle";
  }
});

const alertClass = computed(() => `alert alert-${props.type}`);
</script>
