<template>
  <div class="pj-ui-label">{{ labelHeader }}</div>
  <div class="form-check pj-checkbox form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      :id="inputId"
      data-testid="checkbox"
      :checked="modelValue"
      :disabled="isDisabled"
      @change="updateValue"
    />
    <label class="form-check-label" :for="inputId">{{ labelName }}</label>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
defineProps({
  labelHeader: {
    type: String,
    required: false,
  },
  labelName: {
    type: String,
    required: true,
  },
  inputId: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
    required: false,
  },
});
const emit = defineEmits({
  "update:modelValue": (value) => typeof value === "boolean",
  change: (value) => typeof value === "boolean",
});

const updateValue = (event) => {
  const newValue = event.target.checked;
  emit("update:modelValue", newValue);
  emit("change", newValue);
};
</script>
<style lang="scss" scoped>
.pj-checkbox,
.form-check-label {
  font-size: 18px;
  cursor: pointer;
}
</style>
