<template>
  <div id="qr-wrap" ref="qrWrapDiv">
    <QRCodeVue3
      :value="value"
      :width="newWidth"
      :height="newWidth"
      :myclass="extendedMyClass"
      :imgclass="extendedImgClass"
      :image="image"
      :dotsOptions="{
        type: 'extra-rounded',
        color: '#000000',
      }"
      :cornersSquareOptions="{
        type: 'square',
        color: cornerColor,
      }"
      :imageOptions="{
        hideBackgroundDots: true,
        imageSize: 0.7,
        margin: 0,
      }"
      :qrOptions="qrOptions"
      :key="key"
    />
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import QRCodeVue3 from "qrcode-vue3";
import { ref, watchEffect, onMounted, onUnmounted } from "vue";

export default defineComponent({
  name: "QRCode",
  components: {
    QRCodeVue3,
  },
  props: {
    value: String,
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    qrClass: {
      type: String,
      default: "",
    },
    qrImgClass: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: null,
    },
    cornerColor: {
      type: String,
      default: "#000000",
    },
    qrOptions: {
      default() {
        return {
          typeNumber: 0,
          mode: "Byte",
          errorCorrectionLevel: "H",
        };
      },
      type: Object,
    },
    autoSize: {
      type: Boolean,
    },
  },
  setup(props) {
    const qrWrapDiv = ref(null);
    const extendedMyClass = ref("qr-container " + props.qrClass);
    const extendedImgClass = ref("qr-container " + props.qrImgClass);
    const newWidth = ref(300);
    const windowHeight = ref(1200);
    const windowWidth = ref(600);
    const key = ref(1);

    watchEffect(() => {
      if (windowHeight.value) {
        if (props.autoSize) {
          newWidth.value = Math.max(Math.round(windowHeight.value / 2), 300);
          //console.log("watchEffect", windowHeight.value, newWidth.value);
          key.value++;
        }
      }
    });

    const resizeHandler = (e) => {
      windowHeight.value = e.target.innerHeight;
      windowHeight.value = e.target.innerWidth;
    };
    onMounted(() => {
      window.addEventListener("resize", resizeHandler);
      if (props.autoSize) {
        windowHeight.value = window.innerHeight;
        windowWidth.value = window.innerWidth;
        newWidth.value = Math.max(Math.round(windowHeight.value / 2), 300);
        //console.log("onMounted", windowHeight.value, newWidth.value);
        key.value++;
      }
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
    });

    return {
      extendedImgClass,
      extendedMyClass,
      qrWrapDiv,
      newWidth,
      key,
    };
  },
});
</script>
